import React from 'react';
import './styles/style.scss'

import Sections from './components/Sections';

import CanvasThreeFiber from './components/CanvasThreeFiber';
import Enter from './components/Enter';
import ColorSwitcher from './components/ColorSwitcher';
import Loader from './components/Loader';
import CanvasThreeFiberGyro from './components/CanvasThreeFiberGyro';
import SectionsJump from './components/SectionsJump';
//import { Color } from 'three';

window.addEventListener(
  'contextmenu',
  function (e) {
    e.preventDefault();
  },
  false
);

function App() {
  return (
    <div className="App">
      {/* <Loader /> */}

      <Enter />
      <SectionsJump />
      <ColorSwitcher />
      {/*     <CanvasThreeFiber /> */}

      <CanvasThreeFiberGyro />
    </div>
  )
}

export default App;
