const imagesWebsite = {

    flesh: {
        title: "Flesh TV",
        website: "https://www.fleshtv.world/",
        website02: "Design+development of FleshTV collective website.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/flesh_tv/jpg/flesh_tv_01.jpg",
                srcWebp: "../assets/images/websites/flesh_tv/webp/flesh_tv_01.webp",
                loading: "eager"
            },

        ]
    },

    distortion: {
        title: "post distortion",
        website: "https://post-distortion.ff87c3.eu/",
        website02: `Design+development of an analog photography portfolio proposing an AI interpretation of it.`,
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/post_distortion/jpeg/post_distortion_06.jpg",
                srcWebp: "../assets/images/websites/post_distortion/jpeg/post_distortion_06.jpg",
                loading: "eager"
            },
        ]
    },


    crypto: {
        title: "crypto expressionism",
        website: "https://crypto-expressionism.vercel.app/",
        website02: `Design+development of an interface that distorts traditional trading charts by fetching the live Bitcoin price every minute and processing it on a canvas.`,
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/crypto/jpeg/crypto-expressionism-01.jpg",
                srcWebp: "../assets/images/websites/crypto/webp/crypto-expressionism-01.jpg",
                loading: "eager"
            },
        ]
    },


    eurorue: {
        title: "eurorue",
        website: "https://www.eurorue.eu/",
        website02: `Design+development of a speech-to-text interface recognizing the 24 official languages of the EU. Turn your mic on, say something and let the words materialize.`,
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/eurorue/jpeg/eurorue-01.jpg",
                srcWebp: "../assets/images/websites/eurorue/webp/eurorue-01.webp",
                loading: "eager"
            },
        ]
    },

    ximon: {
        title: "Ximon Lee",
        // website: "https://ximonlee.vercel.app/",
        website: "https://ximonlee.com",
        website02: "Design+development of Ximon Lee portfolio.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/ximon_lee/jpeg/image05.jpg",
                srcWebp: "../assets/images/websites/ximon_lee/webp/image05.webp",
                loading: "eager"
            },
            /*    {
                   key: 2,
                   srcJpeg: "../assets/images/websites/ximon_lee/jpg/ximon_lee_02.jpg",
                   srcWebp: "../assets/images/websites/ximon_lee/webp/ximon_lee_02.webp",
                   loading: "eager"
               },
               {
                   key: 3,
                   srcJpeg: "../assets/images/websites/ximon_lee/jpg/ximon_lee_03.jpg",
                   srcWebp: "../assets/images/websites/ximon_lee/webp/ximon_lee_03.webp",
                   loading: "eager"
               }, */

        ]
    },

    ronald: {
        title: "Ronald Rose",
        website: "https://www.ronaldrose.eu/",
        website02: "Design+development of a conceptual photography portfolio with possibility to load your own pictures instead.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/ronald_rose/jpeg/ronald_rose_08.jpg",
                srcWebp: "../assets/images/websites/ronald_rose/webp/ronald_rose_08.webp",
                loading: "eager"
            },
            /*    {
                   key: 2,
                   srcJpeg: "../assets/images/websites/ronald_rose/jpg/ronald_rose_02.jpg",
                   srcWebp: "../assets/images/websites/ronald_rose/webp/ronald_rose_02.webp",
                   loading: "eager"
               },
               {
                   key: 3,
                   srcJpeg: "../assets/images/websites/ronald_rose/jpg/ronald_rose_03.jpg",
                   srcWebp: "../assets/images/websites/ronald_rose/webp/ronald_rose_03.webp",
                   loading: "eager"
               },
    */


        ]
    },

    haiku: {
        title: "Haiku generator",
        website: "https://ai-ku-generator.vercel.app/",
        website02: "Design+development of an Open AI based haiku generator in several languages.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/haiku_generator/jpeg/haiku_generator_02.jpg",
                srcWebp: "../assets/images/websites/haiku_generator/webp/haiku_generator_02.webp",
                loading: "eager"
            },


        ]
    },

    wordscrawler: {
        title: "words crawler",
        website: "https://words-crawler.vercel.app/",
        website02: "Design+development of an experimental data visualization app, using web crawling to extract words from any websites and rank them by frequency.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/words_crawler/words-crawler03.jpg",
                srcWebp: "../assets/images/websites/words_crawler/words-crawler03.jpg",
                loading: "eager"
            },


        ]
    },

    cgvdbenken: {
        title: "Cgvdbenken",
        website: "https://cgvdbenken.net/",
        website02: "Design of Carolin von den Benken drawings portfolio.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/carolin/webp/carolin-von-den-benken_05.webp",
                srcWebp: "../assets/images/websites/carolin/jpeg/carolin-von-den-benken_05.jpg",
                loading: "eager"
            },


        ]
    },

    dannysays: {
        title: "Danny says",
        website: "https://dannysays.ff87c3.eu/",
        website02: "Design+development of a speech synthesis interface reading through a text out loud.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/danny_says/jpg/danny-says_04.jpg",
                srcWebp: "../assets/images/websites/danny_says/webp/danny-says_04.webp",
                loading: "eager"
            },
            /*   {
                  key: 2,
                  srcJpeg: "../assets/images/websites/danny_says/jpg/danny-says_05.jpg",
                  srcWebp: "../assets/images/websites/danny_says/webp/danny-says_05.webp",
                  loading: "eager"
              },
              {
                  key: 3,
                  srcJpeg: "../assets/images/websites/danny_says/jpg/danny-says_06.jpg",
                  srcWebp: "../assets/images/websites/danny_says/webp/danny-says_06.webp",
                  loading: "eager"
              }, */

        ]
    },


    visualscrawler: {
        title: "visuals crawler",
        website: "https://visuals-crawler.vercel.app/",
        website02: "Design+development of an experimental data visualization app, fetching visuals from any websites and displaying their digital weight.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/visuals_crawler/jpeg/visuals-crawler03.jpg",
                srcWebp: "../assets/images/websites/visuals_crawler/webp/visuals-crawler03.webp",
                loading: "eager"
            },


        ]
    },

    /* 
        barred: {
            title: "Barred Berlin",
            website: "https://barred-berlin.ff87c3.eu/",
            website02: "Design+development of Barred landing page.",
            pictures: [
                {
                    key: 1,
                    srcJpeg: "../assets/images/websites/barred_berlin/jpg/barred_berlin04.jpg",
                    srcWebp: "../assets/images/websites/barred_berlin/webp/barred_berlin04.webp",
    
                },
              
    
            ],
    
        }, */
    /* 
        benoit: {
            website: "https://www.benoit-palop.com/",
            website02: "benoit-palop.com/",
            pictures: [
                {
                    key: 1,
                    srcJpeg: "../assets/images/websites/benoit_palop/jpg/benoit-palop_01.jpg",
                    srcWebp: "../assets/images/websites/benoit_palop/webp/benoit-palop_01.webp",
    
                },
                {
                    key: 2,
                    srcJpeg: "../assets/images/websites/benoit_palop/jpg/benoit-palop_01.jpg",
                    srcWebp: "../assets/images/websites/benoit_palop/webp/benoit-palop_01.webp",
    
                },
                {
                    key: 3,
                    srcJpeg: "../assets/images/websites/benoit_palop/jpg/benoit-palop_03.jpg",
                    srcWebp: "../assets/images/websites/benoit_palop/webp/benoit-palop_03.webp",
    
                },
                {
                    key: 4,
                    srcJpeg: "../assets/images/websites/benoit_palop/jpg/benoit-palop_04.jpg",
                    srcWebp: "../assets/images/websites/benoit_palop/webp/benoit-palop_04.webp",
    
                },
            ],
        }, */





    ex: {
        title: "Ex Magazine",
        website: "https://www.exmagazine.fr/",
        website02: "Design+development of Ex Magazine website.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/ex_magazine/jpg/ex-magazine_02.jpg",
                srcWebp: "../assets/images/websites/ex_magazine/webp/ex-magazine_02.webp",
                loading: "eager"
            },
            /*      {
                     key: 2,
                     srcJpeg: "../assets/images/websites/ex_magazine/jpg/ex-magazine_02.jpg",
                     srcWebp: "../assets/images/websites/ex_magazine/webp/ex-magazine_02.webp",
                     loading: "eager"
                 },
                 {
                     key: 3,
                     srcJpeg: "../assets/images/websites/ex_magazine/jpg/ex-magazine_03.jpg",
                     srcWebp: "../assets/images/websites/ex_magazine/webp/ex-magazine_03.webp",
                     loading: "eager"
                 },
                 {
                     key: 4,
                     srcJpeg: "../assets/images/websites/ex_magazine/jpg/ex-magazine_04.jpg",
                     srcWebp: "../assets/images/websites/ex_magazine/webp/ex-magazine_04.webp",
                     loading: "eager"
                 }, */

        ]
    },



    /*     post: {
            title: "Post distortion",
            website: "https://www.post-distortion.eu/",
            website02: "post-distortion.eu/",
            pictures: [
                {
                    key: 1,
                    srcJpeg: "../assets/images/websites/post_distortion/jpg/post_distortion_05.jpg",
                    srcWebp: "../assets/images/websites/post_distortion/webp/post_distortion_05.webp",
                    loading: "eager"
                },
               
    
            ]
        }, */

    sienna: {
        title: "Sienna Carlisle",
        website: "https://siennacarlisle.com/",
        website02: "Design+development of Sienna Carlisle website.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/sienna_carlisle/jpeg/sienna_carlisle_01.jpg",
                srcWebp: "../assets/images/websites/sienna_carlisle/webp/sienna_carlisle_01.webp",
                loading: "eager"
            },

        ]
    },


    timothee: {
        title: "Timothée Lambrecq",
        website: "https://www.timothee-lambrecq.com/",
        website02: "Design+development of Timothée Lambrecq portfolio.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/timothee_lambrecq/jpeg/timothee_02.jpg",
                srcWebp: "../assets/images/websites/timothee_lambrecq/webp/timothee_02.webp",
                loading: "eager"
            },
            /*     {
                    key: 2,
                    srcJpeg: "../assets/images/websites/timothee_lambrecq/jpg/timothee_lambrecq_02.jpg",
                    srcWebp: "../assets/images/websites/timothee_lambrecq/webp/timothee_lambrecq_02.webp",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../assets/images/websites/timothee_lambrecq/jpg/timothee_lambrecq_03.jpg",
                    srcWebp: "../assets/images/websites/timothee_lambrecq/webp/timothee_lambrecq_03.webp",
                    loading: "eager"
                },
     */


        ]
    },



    waven3: {
        title: "Waven3",
        website: "https://www.waven3.space/",
        website02: "Design+development of Japanese collective Waven3 website.",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/waven3/jpg/waven3_04.jpg",
                srcWebp: "../assets/images/websites/waven3/webp/waven3_04.webp",
                loading: "eager"
            },

            /*       {
                      key: 2,
                      srcJpeg: "../assets/images/websites/waven3/jpg/waven3_02.jpg",
                      srcWebp: "../assets/images/websites/waven3/webp/waven3_02.webp",
                      loading: "eager"
                  },
                  {
                      key: 3,
                      srcJpeg: "../assets/images/websites/waven3/jpg/waven3_03.jpg",
                      srcWebp: "../assets/images/websites/waven3/webp/waven3_03.webp",
                      loading: "eager"
                  },
                  {
                      key: 4,
                      srcJpeg: "../assets/images/websites/waven3/jpg/waven3_04.jpg",
                      srcWebp: "../assets/images/websites/waven3/webp/waven3_04.webp",
                      loading: "eager"
                  }, */
        ]
    },

    info: {
        title: "info",
        website: "",
        website02: "Fascinated by the endless possibilities offered by the web, I play with the boundaries of front-end development by designing and developing interfaces using technologies such as Javascript, React, and Three.js.   I produce works ranging from informative websites - aligned with current UX/UI conventions - to more conceptual and visually driven projects, often breaking traditional functionality or color norms. The latter, focusing on interactivity, randomization or 3D elements, typically requires more time for users to explore, purposely slowing down the absorption of information. Clinical lines and synthetic colors infused with a touch of playfulness: drop me a line at info@ff87c3.eu if you have any digital fantasy. Programmatically made in Berlin.",
        pictures: [
            {
                key: 1,
                rcJpeg: "../assets/images/Portrait.jpg",
                srcWebp: "../assets/images/Portrait.jpg",
                loading: "eager"
            },
        ]
    },

    /*  pixel: {
         title: "Pixel Mitosis",
         website: "https://pixel-mitosis.ff87c3.eu/",
         website02: "Design+development of experimental visual interface allowing the user to draw a random pictures from a stock photography database. Search a topic, mouse the mouse, click to go to the next picture.",
         pictures: [
             {
                 key: 1,
                 srcJpeg: "../assets/images/websites/pixel_mitosis/jpg/pixel_mitosis_02.jpg",
                 srcWebp: "../assets/images/websites/pixel_mitosis/webp/pixel_mitosis_02.webp",
                 loading: "eager"
             },
         ]
     },
  */

}

export default imagesWebsite;



