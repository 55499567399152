import React, { useEffect, useState, useRef } from 'react'
import imagesWebsite from '../assets/database/images-website.js'

const SectionsJump = () => {

    const [imageDimensions, setImageDimensions] = useState({});
    const sectionRefs = useRef([]);
    const touchStartY = useRef(0);

    // Function to handle image load and save dimensions
    const handleImageLoad = (websiteKey, index, event) => {
        const { naturalWidth, naturalHeight } = event.target;
        setImageDimensions(prevDimensions => ({
            ...prevDimensions,
            [`${websiteKey}_${index}`]: { width: naturalWidth, height: naturalHeight }
        }));
    };

    // Scroll to the target section based on index
    const scrollToSection = (index) => {

        if (sectionRefs.current[index]) {
            sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Function to handle desktop scroll
    /*   const handleScroll = (e) => {
          e.preventDefault();  // Prevent default scrolling
  
          // Get the index of the current section in view
          const currentSection = sectionRefs.current.findIndex((section) =>
              section && section.getBoundingClientRect().top >= 0
          );
  
          // Determine the direction and target section
          const nextIndex = e.deltaY > 0
              ? Math.min(currentSection + 1, sectionRefs.current.length - 1)
              : Math.max(currentSection - 1, 0);
  
          scrollToSection(nextIndex);
      };
   */
    const handleScroll = (e) => {
        e.preventDefault(); // Prevent default scrolling

        const currentSection = sectionRefs.current.findIndex((section) =>
            section && section.getBoundingClientRect().top >= 0
        );

        let nextIndex;

        if (e.deltaY > 0) {
            // Scrolling down
            nextIndex = (currentSection + 1) % sectionRefs.current.length; // Loops back to 0
        } else {
            // Scrolling up
            nextIndex = (currentSection - 1 + sectionRefs.current.length) % sectionRefs.current.length;
        }

        scrollToSection(nextIndex);
    };


    const handleMenuClick = (e, index) => {
        e.preventDefault(); // Prevents URL change

        scrollToSection(index); // Uses your existing scroll function
    };

    // Function to handle touch start (for mobile)
    const handleTouchStart = (e) => {
        touchStartY.current = e.touches[0].clientY;
    };

    // Function to handle touch end (for mobile)
    /*   const handleTouchEnd = (e) => {
          const touchEndY = e.changedTouches[0].clientY;
          const deltaY = touchStartY.current - touchEndY;
  
          // Get the index of the current section in view
          const currentSection = sectionRefs.current.findIndex((section) =>
              section && section.getBoundingClientRect().top >= 0
          );
  
          // Determine direction and target section
          const nextIndex = deltaY > 0
              ? Math.min(currentSection + 1, sectionRefs.current.length - 1) // Swipe up
              : Math.max(currentSection - 1, 0);  // Swipe down
  
          scrollToSection(nextIndex);
      }; */

    const handleTouchEnd = (e) => {
        const touchEndY = e.changedTouches[0].clientY;
        const deltaY = touchStartY.current - touchEndY;

        const currentSection = sectionRefs.current.findIndex((section) =>
            section && section.getBoundingClientRect().top >= 0
        );

        let nextIndex;

        if (deltaY > 0) {
            // Swipe up
            nextIndex = currentSection + 1 >= sectionRefs.current.length ? 0 : currentSection + 1;
        } else {
            // Swipe down
            nextIndex = currentSection - 1 < 0 ? sectionRefs.current.length - 1 : currentSection - 1;
        }

        scrollToSection(nextIndex);
    };

    useEffect(() => {
        // Add event listeners for both wheel and touch events
        window.addEventListener('wheel', handleScroll, { passive: false });
        window.addEventListener('touchstart', handleTouchStart, { passive: true });
        window.addEventListener('touchend', handleTouchEnd, { passive: true });

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, []);


    /// LI HIGHLIGHT SCRIPT

    useEffect(() => {
        const liItems = document.querySelectorAll('li');

        liItems.forEach((li) => {
            // Hover effect to highlight the menu item and scroll to the section
            li.addEventListener('mouseover', () => {
                liItems.forEach((li) => li.classList.remove('li_selected'));
                li.classList.add('li_selected');

                const sectionId = li.getAttribute('data-section');
                activateMenuLink(sectionId);
                scrollToSection(sectionId); // Scroll to the section on hover
            });

            // Click effect to directly scroll to the section
            li.addEventListener('click', () => {
                const sectionId = li.getAttribute('data-section');
                scrollToSection(sectionId);
            });
        });

        function activateMenuLink(sectionId) {
            document.querySelectorAll('#menu li').forEach((link) => {
                link.classList.toggle('li_selected', link.getAttribute('data-section') === sectionId);
            });
        }

        function scrollToSection(sectionId) {
            const sectionsContainer = document.getElementById('sections');
            const sectionToShow = document.querySelector(`section#${sectionId}`);
            if (sectionToShow) {
                const scrollTop = sectionToShow.offsetTop;
                sectionsContainer.scrollTo({
                    top: scrollTop,
                    behavior: 'smooth' // Smooth scroll on hover
                });
            }
        }


        // Options for the Intersection Observer
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        // Callback for the Intersection Observer
        function observerCallback(entries) {
            entries.forEach((entry) => {

                if (entry.isIntersecting) {
                    // Get the section ID
                    const sectionId = entry.target.getAttribute('data-section-id');

                    // Activate the corresponding menu link
                    activateMenuLink(sectionId);
                }
            });
        }

        // Create the Intersection Observer
        const observer = new IntersectionObserver(observerCallback, observerOptions);

        // Observe each section
        document.querySelectorAll('section').forEach((section) => {
            observer.observe(section);
        });

    }, []);

    return (
        <main>
            <div id="sections">
                {Object.keys(imagesWebsite).map((websiteKey, index) => (
                    <section ref={(el) => (sectionRefs.current[index] = el)} id={websiteKey} data-section-id={websiteKey} key={websiteKey} >

                        {imagesWebsite[websiteKey].pictures.map((picture, picIndex) => (
                            <a href={imagesWebsite[websiteKey].website} target='_blank' rel="noreferrer" key={`${websiteKey}-${picIndex}`}>
                                <picture>
                                    <source type="image/webp" srcSet={picture.srcWebp}>
                                    </source>
                                    <img src={picture.srcJpeg}
                                        alt={`${websiteKey} website`}
                                        width={imageDimensions[`${websiteKey}_${picIndex}`]?.width || 'auto'}
                                        height={imageDimensions[`${websiteKey}_${picIndex}`]?.height || 'auto'}
                                        onLoad={(event) => handleImageLoad(websiteKey, picIndex, event)} />

                                </picture>
                            </a>
                        ))}


                        <p className="website-url">
                            {imagesWebsite[websiteKey].website02}
                        </p>

                    </section>
                ))}

            </div>

            <ul id="menu">
                {Object.keys(imagesWebsite).map((websiteKey, index) => (
                    <li data-section={websiteKey}
                        key={websiteKey}
                    >
                        <a href={`#${websiteKey}`} onClick={(e) => handleMenuClick(e, index)}>
                            {imagesWebsite[websiteKey].title}
                        </a>
                    </li>
                ))}

                {/*  <li data-section="contact" key="854dfgfg"> <a href="#contact">info</a></li> */}
            </ul>
        </main>
    )
}

export default SectionsJump


