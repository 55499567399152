import React from "react";

const ColorSwitcher = () => {
    const handleClick = () => {
        const rootStyles = document.documentElement.style;

        let currentTextColor = rootStyles.getPropertyValue('--textColor');
        let currentBackgroundColor = rootStyles.getPropertyValue('--backgroundColor');

        // Initialize the CSS variables if they are not set
        if (!currentTextColor) {
            rootStyles.setProperty('--textColor', '#ff87c3');
            currentTextColor = '#ff87c3';
        }
        if (!currentBackgroundColor) {
            rootStyles.setProperty('--backgroundColor', '#ededed');
            currentBackgroundColor = '#ededed';
        }

        const newTextColor = currentTextColor === '#ff87c3' ? '#ffffff' : '#ff87c3';
        const newBackgroundColor = currentBackgroundColor === '#ededed' ? '#ff87c3' : '#ededed';

        rootStyles.setProperty('--textColor', newTextColor);
        rootStyles.setProperty('--backgroundColor', newBackgroundColor);

        document.querySelector('.color-switcher').textContent = newTextColor;
    };

    return (
        <div className="color-switcher" onClick={handleClick}>#ff87c3</div>
    )
};


export default ColorSwitcher;